const aboutData = [
    {
        id: 1,
        image: 'images/1670881392278.jpeg',
        name: 'Janberk Altay',
        description: 'Kurucu / Yazılım Geliştirici',
        link: 'https://www.linkedin.com/in/janberkaltay/'
    },
    {
        id: 2,
        image: "images/1680712315167.jpeg",
        name: 'Alican Teke',
        description: 'UI/UX Geliştirme Uzmanı',
        link: 'https://www.linkedin.com/in/ali-can-teke-313700270/'
    },
];

const aboutCards = () => {
    return aboutData.map(card => {
        return {
            ...card,
            renderCard: () => (
                <>
                <a href={card.link} target="_blank" className="mail-row" rel="noopener noreferrer" >
                <div className='about-card'>
                    <img className="about-img" src={card.image} alt={card.name} />
                    <p className="about-card-header">{card.name}</p>
                    <p className="about-card-description">{card.description}</p>
                </div>
                </a>
                </>
            ),
        };
    });
};

export { aboutCards };
