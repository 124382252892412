import React from "react";
import { Email } from './Email';
import './Contact.css';
import { IconButton } from '@mui/material';
import { LinkedIn, Instagram, WhatsApp } from '@mui/icons-material';

const Contact = () => {


const handleLinkedInClick = () => {
    window.open('https://www.linkedin.com/company/endless-software/', '_blank', 'rel="noopener noreferrer"');
};

const handleInstagramClick = () => {
    window.open('https://instagram.com/endless_yazilim?igshid=MmU2YjMzNjRlOQ==', '_blank', 'rel="noopener noreferrer"');
};

const handleWhatsappClick = () => {
    window.open('https://wa.me/905374896933', '_blank', 'rel="noopener noreferrer"');
};
  return (
    <section className='contact-page'>
      <Email />
      <div className='contact-container'>
        <div className="contact-side">
        <img src="/images/contactArrow.png" alt="contact-img"/>
        <div className="contact-column">
          <h1 className="contact-side-header">İletişime</h1>
          <h1 className="contact-side-header">Geç</h1>
        </div>
        </div>
        <p>Ekibimize katılmak ve dinamik bir çalışma ortamında yer almak için iletişime geçin! </p>
        <div>
                            
                            <IconButton onClick={handleLinkedInClick}>
                                <LinkedIn fontSize='large'/>
                            </IconButton>
                            <IconButton onClick={handleInstagramClick}>
                                <Instagram fontSize='large'/>
                            </IconButton>
                            <IconButton onClick={handleWhatsappClick}>
                                <WhatsApp fontSize='large'/>
                            </IconButton>
                        </div>
      </div>
    </section>
  )
}

export default Contact