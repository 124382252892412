import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import './Navbar.css';

const Navbar = () => {

    const handleWhatsappClick = () => {
        window.open('https://wa.me/905374896933', '_blank', 'rel="noopener noreferrer"');
      };

    const [menuOpen, SetMenuOpen] = useState(false)
    return (
        <nav>
            <Link to='/' className="title">
                <img src="/logo.png" alt="Endless Logo" />
            </Link>
            <div className="menu" onClick={() => {
                SetMenuOpen(!menuOpen)
            }}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={menuOpen ? 'open' : ''}>
                <li> <NavLink to='/'>Anasayfa</NavLink></li>
                <li> <NavLink to='/services'>Hizmetlerimiz</NavLink></li>
                <li> <NavLink to='/about'>Hakkımızda</NavLink></li>
                <li> <NavLink to='/contact'>İletişim</NavLink></li>
                <Link onClick={handleWhatsappClick} className="sale">Hızlı Teklif Al</Link>
            </ul>
        </nav>
    )
}
export default Navbar