import React from 'react'
import './Home.css'
import { NavLink } from "react-router-dom";
import Services from '../Services/Services'

export const Home = () => {
  return (
    <>
      <div className='homepage'>
        <div className='header'>
          <h1 className='title'>
            Endless Yazılım ve Donanım Hizmetleri
          </h1>
          <p className='text'>
            Gelişen Teknoloji ile sizin için dijital dünyadaki büyüme potansiyelini keşfetmek,
            hedef kitlenize ulaşmak ve markanızın başarısını arttırmak için buradayız!  İşletmenizin
            varlığını güçlendirmek, satışlarınızı artırmak ve rekabette öne geçmeniz için kapsamlı çözümler sunar.
          </p>
          <button className='button'> 
          <NavLink className='sale-button' to='/services'>Hizmetlerimizden Yararlanın</NavLink>
          </button>
        </div>
        <img className='img' src="images/anasayfa.png" alt="Homepage-img" />
      </div>
      <Services />
      <div className='vista-header'>
        <h1 className='vista-title'>Vizyonumuz</h1>
        <h1 className='vista-title'>Misyonumuz</h1>
      </div>
      <div className='vista'>
        <div className='vista-container'>
          <img src="images/arrow.png" alt="vision-img" className='image' />
          <p className='vista-text'>
            Yazılım ve donanım sektöründe altyapısı dinamik bir ekipten oluşan ve kendini sürekli geliştirmekte olan bir teknoloji şirketi olarak sektörde güzel bir yer edinmek.
          </p>
        </div>
        <div className='vista-container'>
          <p className='vista-text'>
            Sektörde genç ve dinamik kadromuz ile kurumların teknolojik ihtiyaçlarını karşılamak ve müşterilerimize yenilikçi çözümler üreterek müşteri memnuniyeti sağlamak en önemli amacımızdır.
          </p>
          <img src="images/mission.png" alt="mission-img" className='image' />
        </div>
      </div>
    </>
  )
}