import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Contact.css';
import SendIcon from '@mui/icons-material/Send';

export const Email = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_v8bteei', 'template_hk5hbmo', form.current, 'OiH3MLkjpaRl1mICN')
      .then(
        (result) => {
          console.log(result.text);
          toast.success('Mesajınız Başarıyla Gönderildi!', {
            position: toast.POSITION.TOP_CENTER
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className='contact'>
      <ToastContainer />
      <form ref={form} onSubmit={sendEmail}>
        <input className="form-input" type="text"  name="name" placeholder="İsim Soyisim" />
        <input className="form-input" type="email" name="email" placeholder="Mail Adresiniz" />
        <input className="form-input" type="text" name="subject" placeholder="Konu" />
        <textarea name="message" placeholder="Lütfen mesajınızı giriniz"/>
        <Button style={{backgroundColor:'#1AB3C5', borderRadius: 8, width: 160}} variant="contained" endIcon={<SendIcon />} type="submit">
          Gönder
        </Button>
      </form>
    </div>
  );
};