const servicesData = [
    {
      id: 1,
      image: '/images/card1.png',
      name: 'İnternet Sitesi',
      description: 'Şahsi ve Kurumsal Website hizmetimiz ile birlikte işletmeninizin dijital dünyada varlığını güçlendiriyoruz. İhtiyaçlarınıza özel yapılan websiteniz ile işleriniz daha kolay.'
    },
    {
      id: 2,
      image: '/images/card2.png',
      name: 'Mobil Uygulama Yazılımı',
      description: 'İstek ve ihtiyaçlarınıza göre tasarlanan mobil uygulama yazılım hizmetimiz işinizi kolaylaştırmada bir numara bu hizmet ile birlikte sizlere kesintisiz ve garantili hizmet sunuyoruz.',
    },
    {
      id: 3,
      image: '/images/card3.png',
      name: 'Sosyal Medya Yönetimi',
      description: 'Gelişen teknoloji ile sosyal medya popülerliği hızla yayılıyor. Kurumunuz için sunulan bu hizmet ile hiç yorulmadan daha fazla kişiye ulaşabilir ve işletmenizi ön plana çıkarabilirsiniz.'
    },
    {
      id: 4,
      image: '/images/card4.png',
      name: 'Teknik Donanım Hizmeti',
      description: 'Kurumunuza ait bilgisayar gibi çeşitli teknolojik cihazların periyodik bakım ve tamiratları yapılarak kurumunuzda çalışan personelin daha hızlı ve daha performanslı hizmet sunması sağlanmış olur.'
    },
  ];
  
  const createCards = () => {
    const handleWhatsappClick = () => {
      window.open('https://wa.me/905374896933', '_blank', 'rel="noopener noreferrer"');
    };

    return servicesData.map(card => {
      return {
        ...card,
        renderCard: () => (
          <div className='card'>
            <img src={card.image} alt={card.name} />
            <p className="card-header">{card.name}</p>
            <p className="description">{card.description}</p>
            <button onClick={handleWhatsappClick} className='button'>Hızlı Teklif Al</button>
          </div>
        ),
      };
    });
  };
  
  export { createCards };
  