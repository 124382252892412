import React from 'react';
import './Services.css';
import { createCards } from './ServicesData';

const Services = () => {
  const cards = createCards();
  return (
    <>
      <div className='services'>
        <h1>Hizmetlerimiz</h1>
      </div>
      <div className='container'>
        {cards.map(card => (
          <>
            {card.renderCard()}
          </>
        ))}
      </div>
    </>
  );
};

export default Services;
