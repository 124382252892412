import React from 'react';
import './Footer.css'
import { NavLink } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { IconButton } from '@mui/material';
import { Facebook, LinkedIn, Instagram, WhatsApp } from '@mui/icons-material';

const Footer = () => {
    const handleFacebookClick = () => {
        window.open('https://www.linkedin.com/company/endless-software/', '_blank', 'rel="noopener noreferrer"');
    };

    const handleLinkedInClick = () => {
        window.open('https://www.linkedin.com/company/endless-software/', '_blank', 'rel="noopener noreferrer"');
    };

    const handleInstagramClick = () => {
        window.open('https://instagram.com/endless_yazilim?igshid=MmU2YjMzNjRlOQ==', '_blank', 'rel="noopener noreferrer"');
    };

    const handleWhatsappClick = () => {
        window.open('https://wa.me/905374896933', '_blank', 'rel="noopener noreferrer"');
    };

    return (
        <footer className='footer'>
            <div>
                <div className="footer-row">
                    <div className="column">
                        <img src="./images/logo2.png" alt="Endless Logo" width={100} />
                        <p>Tarsus, Mersin</p>
                        <a href="tel:537-489-69-33" className='mail-row' ><LocalPhoneIcon className='icon' />0537 489 69 33</a>
                        <p className='mail-row'><MailOutlineIcon className='icon' />endlessswfbusiness@gmail.com</p>
                    </div>
                    <div className="column">
                        <h3>Servisler</h3>
                        <NavLink className={'mail-row'} to='/services'>Hizmetlerimiz</NavLink>
                        <NavLink className={'mail-row'} to='/about'>Hakkımızda</NavLink>
                        <NavLink className={'mail-row'} to='/contact'>İletişim</NavLink>
                        <NavLink ><button onClick={handleWhatsappClick} className='button'>Hemen Teklif Alın</button></NavLink>
                    </div>
                    <span></span>
                    <div className="column">
                        <h3>Sosyal Medya Hesaplarımız</h3>
                        <div>
                            <IconButton  onClick={handleFacebookClick}>
                                <Facebook fontSize='large'/>
                            </IconButton>
                            <IconButton onClick={handleLinkedInClick}>
                                <LinkedIn fontSize='large'/>
                            </IconButton>
                            <IconButton onClick={handleInstagramClick}>
                                <Instagram fontSize='large'/>
                            </IconButton>
                            <IconButton onClick={handleWhatsappClick}>
                                <WhatsApp fontSize='large'/>
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className='dev'>Tüm Hakları Saklıdır &copy; 2023 Endless Software</div>
            </div>
        </footer>
    );
}
export default Footer;
