import React from 'react'
import { aboutCards } from './AboutData';
import { NavLink } from "react-router-dom";
import './About.css'

export const About = () => {
  const aboutCard = aboutCards();
  return (
    <>
      <h1 className='about-header'> Endless Software Hakkında</h1>
      <p className='about-text'>
        Biz, Tarsus Üniversitesi'nde öğrenim gören bir grup öğrenci olarak faaliyet gösteren bir start-up şirketiyiz. 
        Amacımız,
        kurumların teknolojik ihtiyaçlarını karşılamak ve yenilikçi çözümler sunmaktır.
        Teknolojik gereksinimlerinizi karşılamak için profesyonel bir yaklaşım sergiliyoruz. Müşteri memnuniyetine önem 
        veriyor ve ihtiyaçlarınızı anlamak için özen gösteriyoruz.
        Ekibimiz, alanında deneyimli kişilerden oluşmaktadır.
        Sunduğumuz hizmetler arasında web ve mobil uygulama geliştirme, donanım ve yazılım desteği, sosyal medya yönetimi yer almaktadır.
        Müşterilerimize özel çözümler sunmak için sürekli olarak sektördeki yenilikleri takip ediyoruz.
        Sizinle çalışmak ve teknolojik gereksinimlerinizi karşılamak için buradayız. Bize ulaşarak nasıl yardımcı olabileceğimizi konuşalım.
        Saygılarımızla,
        </p>
        
      <p className='about-text-company'>
        -Endless Software Ekibi-
        </p>

      <h1 className='about-header'>Takımımızla Tanışın</h1>
      <div className='about-container'>
        {aboutCard.map(card => (
          <>
            {card.renderCard()}
          </>
        ))}
      </div>
      <div className='about-ik'>
        <div className='about-ik-container'>
          <div className='about-ik-text'>
            <p>Ekibimize katılmak ve dinamik bir çalışma ortamında yer almak için iletişime geçin!</p>
            <button className='button-ik'> <NavLink to='/contact' className='sale-button'>İletişime Geç</NavLink></button>
          </div>
          <img className='about-ik-img' src="./images/aboutpage.png" alt="about-ik-img" />
        </div>
      </div>
    </>
  )
}
